<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div v-if="isAdmin || checkAccess('sites-selling')">
    <report-list-page
        page-title="Sites Selling Report"
        page-icon="mdi-sale"
        :descending="true"
        :headers="headers"
        state-end-point="sitesStatement.sitesSelling"
        :actions="actions"
        :show-component="showComponent"
        :show-grand-total="false"
        sort="site_url"
        item-key="id"
    />
  </div>
</template>

<script>
import ReportListPage from "../../components/ReportListPage";
import IsAuthenticated from "../../middleware/IsAuthenticated";
export default {
  name: "SitesSellingReport",
  mixins: [IsAuthenticated],
  components: {ReportListPage},
  data() {
    return {
      headers: [
        {
          text: "Site URL",
          value: "site_url",
        },
        {
          text: "Last Sold Date",
          value: "saleDate",
        },
        {
          text: "Total Post",
          value: "totalPost",
          align: "center",
        },
      ],
      actions: {
        load: "loadSitesSelling",
      },
      showComponent: {
        isUser: false,
        isStatus: false,
        isMethod: false,
        isEmail: false,
        isSite: false,
        isPDF: false,
        isDate: true
      }
    }
  }
}
</script>

<style scoped>

</style>